import React from "react";
import { getAccessToken, isAuthenticated } from "../services/auth";
import LoadingTextAnimation from "../components/loader"

// AuthSSP page class
class AuthCallbackPage extends React.Component{

    // constructor() {
    //     super();
    // }

    // function that runs when the page is being loaded
    componentDidMount(){
        this.setState({ isLoading: true });
        isAuthenticated(true);
        const interval = setTimeout(() => {
            if(getAccessToken()){
                this.setState({ isLoading: false });
                this.setState({ isSuccess: true });
            }else{
                this.setState({ isLoading: false });
                this.setState({ isSuccess: false });
            }
            clearInterval(interval);
        },5000);
    }

    // state var
    state = {
        isLoading : true
    };

    render () {
        return (<div className="auth-popup">
                {this.state.isLoading ? 
                <>
                <LoadingTextAnimation/> 
                </>
                 : 
                this.state.isSuccess ? (
                    <>
                    <h2><p className="auth-popup__success">Logged in successfully !</p></h2>
                    </>
                ) : 
                    <>
                    <h2><p className="auth-popup__failed">Login Failed !</p></h2>
                    </>
            }
            </div>);
    }
}

export default AuthCallbackPage;