import * as React from "react"

const LoadingTextAnimation = ({loadingText = "Loading..."}) => {
    return (
        <div className="flex h-screen animate-pulse justify-center items-center">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl align-middle">
                <span className="block text-black">{loadingText}</span>
            </h1>   
        </div>
    );
}

export default LoadingTextAnimation